import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Paragraph from "../components/paragraph/Paragraph";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const NotFoundPage = ({data}) => {
  let metaTitle = `${data.nodePage.title} | ${data.site.siteMetadata.title} | ${data.site.siteMetadata.slogan}`;
  return (
    <Layout data={data}>
      <GatsbySeo
        title={metaTitle}
      />
      <h1>{data.nodePage.title}</h1>
      {data.nodePage.relationships.field_main_paragraphs.map((paragraph, i) => {
        return <Paragraph key={i} paragraph={paragraph} data={data} />
      })}
    </Layout>
  )
}

export const query = graphql`
query NotFoundPageQuery {
  site {
    ...SiteHeader
  }
  nodePage(path: {alias: {eq: "/404-seite-nicht-gefunden"}}) {
    id
    title
    status
    relationships {
      field_main_paragraphs {
        ... on paragraph__audio {
          ...ParagraphAudio
        }
        ... on paragraph__call_to_action {
          ...ParagraphCallToAction
        }
        ... on paragraph__columns {
          ...ParagraphColumns
        }
        ... on paragraph__html_text {
          ...ParagraphText
        }
        ... on paragraph__node_reference {
          ...ParagraphNodeReference
        }
        ... on paragraph__slideshow {
          ...ParagraphSlideshow
        }
        ... on paragraph__video_embed {
          ...ParagraphVideoEmbed
        }
      }
    }
  }
}
`

export default NotFoundPage
